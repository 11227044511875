import dayjs from '@/plugins/dayjs'

export default {
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    formValidated() {
      if (this.isQuickEntry && this.formValid && this.encounter.is_quick_entry_validated) return true

      return (this.formValid && this.encounter.is_validated) || this.encounter.is_signed
    },
    computedEncounter() {
      return { ...this.encounter }
    },
    patientAge() {
      return this.$date().diff(this.patient.dob, 'year')
    },
    invalidVisitDate() {
      const dos = this.encounter.visit_date
      return !this.$custom.isDateValid(dos)
        || dayjs(dos).isBefore(dayjs().subtract(1, 'year'), 'day')
        || dayjs(dos).isAfter(dayjs().startOf('day'))
    },
  },
  watch: {
    // This tracks if the encounter has changed in any way and if so sets is_validated/is_synced to false
    computedEncounter: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.mounted) {
          this.encounter.is_synced = false

          // this.$store.commit('encounters/synced', false)
          this.encounterChanged = true
          if (
            newValue.is_validated
            && oldValue.is_validated
            && !this.encounter.is_signed
          ) {
            this.encounter.is_validated = false

            // this.$store.commit('encounters/encounterChanged', this.encounter)
          } else if (
            this.isQuickEntry
            && newValue.is_quick_entry_validated
            && oldValue.is_quick_entry_validated
          ) {
            this.encounter.is_validated = false

            // this.$store.commit('encounters/encounterChanged', this.encounter)
          }
        }
      },
    },

    /* TODO: I don't think this is needed, seems to save attachments just fine without
    'encounter.encounter_attachments': {
      deep: true,
      handler() {
        this.$store.commit('encounters/updateEncounter', { encounter: this.encounter })
      },
    },
    */
  },
  mounted() {

    // Make sure we're on the correct practice type route
    if (this.practiceType !== this.encounter.practice_type_id) {
      this.$router.push({
        name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).encounterRoute,
        query: { id: this.encounter.id },
      })
    }

    // If signed/synced or deferred care, redirect to encounter note
    if ((this.encounter.is_signed && this.encounter.is_synced) || this.encounter.is_deferred_care) {
      this.$router.push({
        name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).noteRoute,
        query: { id: this.encounter.id },
      })
    }

    this.$store.dispatch('loading', false)
  },
  updated() {
    this.mounted = true
  },
  methods: {
    cancelEncounter() {
      /* This isn't needed after the Vue Router 'beforeRouteLeave' guard was added in the Encounters mixin
      this.$root.confirm({
        title: 'Discard Encounter Changes?',
        body: 'Are you sure you wish to cancel all changes to this encounter?',
        cancel: 'No',
        confirm: 'Discard',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          this.exit()
        }
      })
      */

      this.exit()
    },
    saveDraft() {
      this.encounter.modified = this.$custom.utcNow()
      this.encounter.is_validated = false
      this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
      this.encounterChanged = false
      this.exit()
    },
    saveEncounter() {
      this.encounter.modified = this.$custom.utcNow()
      this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
      this.encounterChanged = false
      this.exit()
    },
    validateEncounter() {
      if (this.formValid && !this.encounter.is_validated) {
        this.encounter.modified = this.$custom.utcNow()
        this.encounter.is_validated = true
        this.encounter.is_synced = false
        this.$root.confirm({
          titleIcon: this.icons.mdiCheckDecagram,
          titleIconColor: 'success',
          title: this.isQuickEntry
            ? 'Quick Entry Validated'
            : 'Encounter Validated',
          body: this.isQuickEntry
            ? 'Quick entry is validated and is now ready to include in communication log'
            : 'Encounter is validated and can now be reviewed, signed, and synced',
          cancel: false,
        }).then(() => {
          this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
          if (this.isQuickEntry) {
            this.exit()
          } else {
            this.viewSurgicalNote()
          }
        })
      } else {
        this.$root.confirm({
          titleIconColor: 'error',
          title: this.isQuickEntry
            ? 'Quick Entry Doesn\'t Pass Validation!'
            : 'Encounter Note Doesn\'t Pass Validation!',
          body: 'Please complete all required and non-validated fields. You will now automatically scroll to the first non-validated field.',
          cancel: false,
        }).then(() => {
          // Highlight non-validated fields and smooth-scroll to first section
          this.highlightForm = false
          this.$nextTick(() => {
            this.highlightForm = true
          })
          const field = document.querySelector('.required-field, .v-input--has-state')
          if (field) {
            const section = field.closest('.note-section')
            if (section) {
              window.scrollTo({ top: section.offsetTop - 85, behavior: 'smooth' })
            }
          }
        })
      }
    },
    viewSurgicalNote() {
      this.$store.commit('encounters/updateEncounter', { encounter: this.encounter, patient: this.patient })
      this.encounterChanged = false
      this.$router.push({
        name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).noteRoute,
        query: { id: this.encounter.id },
      })
    },
  },
}
