import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" "),_c(VSpacer),_c('patient-name',{attrs:{"patient":_vm.patient}})],1),_c(VCardTitle,{staticClass:"v-card__subtitle pt-2 pb-0"},[(_vm.visitType)?_c('div',{class:{
        'visitTypeNew': _vm.visitType === 'New',
        'visitTypeFU': _vm.visitType !== 'New',
        'text-uppercase': true
      }},[_vm._v(" "+_vm._s(_vm.visitType)+" ")]):_vm._t("default"),_c(VSpacer),_c('patient-info',{staticClass:"align-self-start",attrs:{"patient":_vm.patient}})],2),(_vm.notice)?_c('alert',{staticClass:"ma-5 text-uppercase",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.notice)+" ")]):_vm._e(),(_vm.patient.no_insurance)?_c('alert',{staticClass:"ma-5"},[_vm._v(" Patient is uninsured or patient’s insurance does not authorize services. Please discuss the need for approval or authorization for future visits with facility staff. Contact billing with any questions. ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }