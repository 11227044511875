<template>
  <fragment>
    <!-- title -->
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
      <patient-name
        :patient="patient"
      ></patient-name>
    </v-card-title>

    <!-- patient info -->
    <v-card-title class="v-card__subtitle pt-2 pb-0">
      <div
        v-if="visitType"
        :class="{
          'visitTypeNew': visitType === 'New',
          'visitTypeFU': visitType !== 'New',
          'text-uppercase': true
        }"
      >
        {{ visitType }}
      </div>
      <slot v-else></slot>

      <v-spacer></v-spacer>

      <patient-info
        class="align-self-start"
        :patient="patient"
      ></patient-info>
    </v-card-title>

    <alert
      v-if="notice"
      class="ma-5 text-uppercase"
      color="error"
    >
      {{ notice }}
    </alert>

    <alert
      v-if="patient.no_insurance"
      class="ma-5"
    >
      Patient is uninsured or patient’s insurance does not authorize services.
      Please discuss the need for approval or authorization for future visits with facility staff.
      Contact billing with any questions.
    </alert>
  </fragment>
</template>

<script>
import '@/components/patients/primitives'

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    patient: {
      type: Object,
      default: null,
    },
    visitType: {
      type: String,
      default: null,
    },
  },
  computed: {
    notice() {
      return this.patient.is_deceased ? `Patient Deceased ${this.$date(this.patient.date_deceased).format('MM/DD/YYYY')}` : false
    },
  },
}
</script>

<style lang="scss">
.not-bold {
  font-weight: normal !important;
}

.visitTypeNew,
.visitTypeFU {
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  width: 120px;
  text-align: center;
}

.visitTypeNew {
  background-color: green;
}

.visitTypeFU {
  background-color: red;
}
</style>
